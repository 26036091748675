import { isLoggedIn } from 'soapbox/utils/auth';

import api from '../api';

import type { AxiosError } from 'axios';
import type { AppDispatch, RootState } from 'soapbox/store';
import type { APIEntity } from 'soapbox/types/entities';

const SCROBBLES_FETCH_REQUEST = 'SCROBBLES_FETCH_REQUEST';
const SCROBBLES_FETCH_SUCCESS = 'SCROBBLES_FETCH_SUCCESS';
const SCROBBLES_FETCH_FAIL    = 'SCROBBLES_FETCH_FAIL';

const fetchScrobbles = (accountId: string, limit: number = 1) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (!isLoggedIn(getState)) return;

    const sessionStorageKey = nextScrobbleKey(accountId);
    const nextScrobbleTime = sessionStorage.getItem(sessionStorageKey);

    if (nextScrobbleTime && new Date().getTime() < parseInt(nextScrobbleTime)) return;

    const nextScrobble = new Date();
    nextScrobble.setSeconds(nextScrobble.getSeconds() + 60);

    sessionStorage.setItem(sessionStorageKey, nextScrobble.getTime().toString());

    dispatch(fetchScrobblesRequest(accountId));
    return api(getState)
      .get(`/api/v1/pleroma/accounts/${accountId}/scrobbles?limit=${limit}`)
      .then(response => {
        dispatch(fetchScrobblesSuccess(accountId, response.data));
      })
      .catch(error => {
        dispatch(fetchScrobblesFail(accountId, error));
      });
  };

const nextScrobbleKey = (accountId: string) => `nextScrobbleFor:${accountId}`;

const fetchScrobblesRequest = (accountId: string) => ({
  type: SCROBBLES_FETCH_REQUEST,
  accountId,
});

const fetchScrobblesSuccess = (accountId: string, scrobbles: APIEntity[]) => ({
  type: SCROBBLES_FETCH_SUCCESS,
  accountId,
  scrobbles,
});

const fetchScrobblesFail = (accountId: string, error: AxiosError) => ({
  type: SCROBBLES_FETCH_FAIL,
  error,
  accountId: accountId,
});


export {
  SCROBBLES_FETCH_REQUEST,
  SCROBBLES_FETCH_SUCCESS,
  SCROBBLES_FETCH_FAIL,
  fetchScrobbles,
  nextScrobbleKey,
};