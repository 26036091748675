import z from 'zod';

const scrobbleSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  title: z.string().catch('Untitled'),
  artist: z.string().optional().catch(undefined),
  album: z.string().optional().catch(undefined),
  length: z.number().optional().catch(undefined),
  url: z.string().optional().catch(undefined),
  created_at: z.string().datetime().catch(new Date().toUTCString()),
});

type Scrobble = z.infer<typeof scrobbleSchema>;

export { scrobbleSchema, type Scrobble };
