import { Map as ImmutableMap } from 'immutable';

import { type Scrobble, scrobbleSchema } from 'soapbox/schemas';

import {
  SCROBBLES_FETCH_SUCCESS,
  nextScrobbleKey,
} from '../actions/scrobbles';

import type { AnyAction } from 'redux';
import type { APIEntity } from 'soapbox/types/entities';

type State = ImmutableMap<string, Scrobble>;
type APIEntities = Array<APIEntity>;

const normalizeScrobbles = (state: State, accountId: string, scrobbles: APIEntities) => {
  scrobbles.forEach(scrobble => {
    try {
      scrobble.accountId = scrobble.account.id;
      state = state.set(scrobble.id, scrobbleSchema.parse(scrobble));
    } catch (_e) {
      // do nothing
    }
  });

  if (scrobbles.length === 0) {
    const tomorrow = new Date();
    tomorrow.setHours(tomorrow.getHours() + 24);
    sessionStorage.setItem(nextScrobbleKey(accountId), tomorrow.getTime().toString());
  }

  return state;
};

export default function scrobbles(state: State = ImmutableMap<string, Scrobble>(), action: AnyAction) {
  switch (action.type) {
    case SCROBBLES_FETCH_SUCCESS:
      return normalizeScrobbles(state, action.accountId, action.scrobbles);
    default:
      return state;
  }
}
